<template>
  <b-card>
    <h2 class="h4 mb-2">{{ $t('Informations') }}</h2>
    <ul class="list-unstyled">
      <li class="d-flex">
        <b-avatar variant="light-primary" square class="mr-1">
          <feather-icon icon="MapPinIcon" />
        </b-avatar>
        <span class="d-inline-block align-self-center">
            <span v-if="event.eventlocation == 2">
              {{ $t('Online Event') }}. <br/>
              {{ $t('You will get access to Event Room once the Booking Process is successfully completed') }}
            </span>
            <span v-else>
                  {{ event.street }} <br/>
                  {{ event.postal }} {{ event.city }} <br/>
            </span>
        </span>
      </li>
      <li class="d-flex mt-1">
        <b-avatar variant="light-primary" square class="mr-1">
          <feather-icon icon="GlobeIcon" />
        </b-avatar>
        <span class="align-self-center">{{ $t('Language') }}: {{ $t('lang:'+event.language) }}</span>
      </li>
      <li class="d-flex mt-1">
        <b-avatar variant="light-primary" square class="mr-1">
          <feather-icon icon="UsersIcon" />
        </b-avatar>
        <span class="align-self-center">{{ $t('Participants') }}: {{ event.min_members }} -  {{ event.max_members }}</span>
      </li>
      <li class="mt-1 d-flex" v-if="event.target">
        <b-avatar variant="light-primary" square class="mr-1">
          <feather-icon icon="TargetIcon" />
        </b-avatar>
        <span class="d-inline-block align-self-center">{{ $t('Targetgroup') }}: {{ event.target }}</span>
      </li>
      <li class="mt-1 d-flex" v-if="event.agenda">
        <b-avatar variant="light-primary" square class="mr-1">
          <feather-icon icon="ListIcon" />
        </b-avatar>
        <span class="d-inline-block align-self-center">{{ $t('Agenda') }}: {{ event.agenda }}</span>
      </li>
    </ul>
  </b-card>
</template>
<script>
import {BAvatar, BCard, BCardText, BCol, BMedia, BMediaAside, BMediaBody, BRow} from "bootstrap-vue";

export default {
  name: "EventInfoBox",
  props: ['event', 'expert'],
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText
  },
  data() {
    return {
      languages: [
        {
          value: 'de',
          text: this.$t('German')
        },
        {
          value: 'en',
          text: this.$t('English')
        },
        {
          value: 'es',
          text: this.$t('Spanish')
        },
      ],
    }
  },
  methods: {
    getLanguageNameByISOCode(lang) {
      let language = this.languages.filter(obj => {
        return obj.value === lang
      })
      if(!language.length) return lang

      return language[0].text
    }
  }
}
</script>

<style lang="scss">
</style>
