<template>
  <b-card :title="$t('Event gallery')" class="p-md-4 p-0" v-if="event.gallery.length" >
    <b-row class="border pt-1">
      <b-col cols="12" sm="12" md="12" lg="2" xl="2" v-for="image in event.gallery" :key="image.id" class="mb-1">
        <img :src=" image.url" class="w-100 h-100" style="object-fit: cover"/>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {BCard, BRow, BImg, BCol} from "bootstrap-vue";
export default {
  name: "EventGallery",
  props: ['event'],
  components: {
    BRow,
    BCard,
    BImg,
    BCol
  }
}
</script>
