<template>
    <b-card>
    <h2 class="h4">{{ $t('Appointments') }}</h2>
    <p class="mb-2">
      <span v-if="event.booking_type == 2">
      {{ $t('You define the dates for this event independently in the booking process.') }}
      </span>
      <span v-else>
        {{ $t('The dates for this event are given by the expert.') }}
      </span>
      {{ $tc('This event consists of a total of {count} dates.', event.timeslots.length, { count: event.timeslots.length }) }}
    </p>

    <app-timeline>
      <app-timeline-item variant="success" :fillBorder="true" :icon-text="(index + 1) + ''"
                         v-for="(timeslot, index) in event.timeslots" :key="timeslot.id"
                         class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between">
          <h6>{{ timeslot.topic }}</h6>
        </div>
        <p class="mb-0 d-flex align-items-center">
          <b-avatar variant="light-primary" square>
            <feather-icon icon="ClockIcon" />
          </b-avatar>
          <span class="timeline-item-duration" v-if="event.booking_type == 2">{{ $t('Duration') }}: {{ timeslot.booking_time }} {{ $t('Minutes') }}</span>
          <span class="timeline-item-duration" v-else>
            <span v-if="timeslot.start_date == timeslot.end_date">
              {{ formateDate(timeslot.start_date) }}: {{ timeslot.start_time }} - {{ timeslot.end_time }}
            </span>
            <span v-else>
              {{ formateDate(timeslot.start_date) }} {{ timeslot.start_time }} - {{ formateDate(timeslot.end_date) }} {{ timeslot.end_time }}
            </span>
          </span>
        </p>
      </app-timeline-item>
    </app-timeline>
    </b-card>
</template>
<script>
import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BCard,
  BCardText,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
  BButton,
  BCollapse,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from "moment";

export default {
  name: "EventInfoBox",
  props: ['event', 'expert'],
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    BButton,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    BBadge,
    AppTimeline,
    AppTimelineItem
  },
  data() {
    return {
      counter: 0
    }
  },

  methods: {
    formateDate(value) {
      return moment(String(value)).format("DD.MM.YYYY")
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';
.timeline-item-duration {
  margin-left: 10px;
}
</style>

