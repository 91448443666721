<template>
  <div v-if="SINGLE_EXPERT_EVENT && SINGLE_EXPERT" v-show="loaded">
    <b-alert
        variant="danger"
        :show="SINGLE_EXPERT_EVENT.is_expired"
    >
      <div class="alert-body">
        <span>{{ $t('Unfortunately the registration period has expired and a booking is no longer possible.') }}</span>
      </div>
    </b-alert>

    <b-card>
      <b-row>
        <b-col xl="6" md="8">
          <div class="bg-light p-1 d-flex align-items-center">
            <b-avatar
                v-if="SINGLE_EXPERT_EVENT.experts[0].expertdetails.avatar"
                size="60px"
                :src="SINGLE_EXPERT_EVENT.experts[0].expertdetails.avatar.url"
            />
            <b-avatar v-else variant="primary">
              <feather-icon icon="UserIcon"/>
            </b-avatar>
            <span class="d-inline-block ml-1">
              <strong class="d-block">{{ SINGLE_EXPERT.firstname }} {{ SINGLE_EXPERT.name }}</strong>
              {{ $t('Host') }}
            </span>
          </div>
          <h1 class="h4 mt-2">{{ SINGLE_EXPERT_EVENT.name }}</h1>
          <hr>
          <div class="event-description">
            <img class="w-100 mb-2 d-block d-md-none" v-if="SINGLE_EXPERT_EVENT.image" :src="SINGLE_EXPERT_EVENT.image.url" alt="">

            {{ SINGLE_EXPERT_EVENT.informations }}
          </div>

          <event-info-box :event="SINGLE_EXPERT_EVENT"></event-info-box>

          <event-booking-button class="mt-1" :event="SINGLE_EXPERT_EVENT"/>
        </b-col>
        <b-col xl="6" md="4" class="mt-2 mt-sm-0 d-none d-md-block">
          <img class="w-100" v-if="SINGLE_EXPERT_EVENT.image" :src="SINGLE_EXPERT_EVENT.image.url" alt="">
        </b-col>
      </b-row>
    </b-card>
    <b-row class="match-height">
      <b-col md="4">
        <event-time-line :event="SINGLE_EXPERT_EVENT"/>
      </b-col>
      <b-col md="4">
        <event-information :event="SINGLE_EXPERT_EVENT"/>
      </b-col>
      <b-col md="4">
        <event-payment :event="SINGLE_EXPERT_EVENT"/>
      </b-col>
    </b-row>
    <b-card class="">
      <event-booking-button :event="SINGLE_EXPERT_EVENT"/>
    </b-card>
  </div>
</template>
<script>


import {mapActions, mapGetters} from "vuex";
import EventGeneral from "@/views/events/event-single/event-parts/EventGeneral";
import EventInformationOld from "@/views/events/event-single/event-parts/EventInformationOld";
import EventInformation from "@/views/events/event-single/event-parts/EventInformation";
import EventGallery from "@/views/events/event-single/event-parts/EventGallery";
import EventInfoBox from "@/views/events/event-single/event-parts/EventInfoBox";
import EventTimeLine from "@/views/events/event-single/event-parts/EventTimeLine";
import EventPayment from "@/views/events/event-single/event-parts/EventPayment";
import EventBookingButton from "@/views/events/event-single/event-parts/EventBookingButton";

import {
  BCol,
  BRow,
  BCard,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BButton,
  BBadge,
  BAlert,
} from "bootstrap-vue";

export default {
  components: {
    EventGallery,
    EventInformationOld,
    EventInformation,
    EventGeneral,
    EventInfoBox,
    EventTimeLine,
    EventPayment,
    EventBookingButton,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    BButton,
    BBadge,
    BAlert,
  },
  data() {
    return {
      loaded: false,
    }
  },
  methods: {
    ...mapActions(['GET_SINGLE_EXPERT_EVENT', 'GET_SINGLE_EXPERT'])
  },
  mounted() {
    this.GET_SINGLE_EXPERT_EVENT(this.$route.params.eventid).then(() => {
      this.GET_SINGLE_EXPERT(this.SINGLE_EXPERT_EVENT.experts[0].id).then((responce) => {
        this.loaded = true
      })
    })
  },
  computed: {
    ...mapGetters(['SINGLE_EXPERT_EVENT', 'SINGLE_EXPERT'])
  }
}
</script>

<style lang="scss">
.card_info_item {
  margin-bottom: 30px;

  &.empty_card_image {
    margin-left: 60px;
  }

  &.margin_without_image {
    margin-left: 60px;
  }

  &.card-with-image {
    border-left: none;

    .image_side {
      padding-right: 15px;
      width: 65px;

      display: flex;
      align-items: center;
      justify-content: end;

      &.with_icon {
        display: flex;
        align-items: center;
        justify-content: end;
      }

      .icon_box {
        background: rgb(233, 241, 242);
        border-radius: 5px;
        justify-content: center;
        display: flex;
        padding: 15px;

      }
    }
  }

  .info_item_title {
    font-size: 18px;
    padding-bottom: 5px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .card_content {
    border-left: 1px solid #d8d6de;
    padding: 10px 15px;
  }
}

.book_event {
  background-color: rgb(1, 101, 101) !important;
  border-color: rgb(1, 101, 101) !important;
}

@media screen and (max-width: 960px) {
  .card_info_item.card-with-image .image_side,
  .card_info_item.card-with-image .image_side .icon_box {
    display: none;
  }
  .card-with-image {
    flex-direction: column;

  }
  .image_side.with_icon,
  .card_info_item.empty_card_image,
  .card_info_item.card-with-image, {
    margin-left: 0px;
    margin-bottom: 20px;

    .image_side {
      justify-content: start;

      &.with_icon {

        justify-content: start;
      }
    }


  }
  .card-with-image {
    padding-left: 0;
  }
}

</style>
