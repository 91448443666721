<template>
  <div class="bg-light px-1 pt-1 mt-1">
    <b-row>
      <b-col class="mb-1">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar size="40" variant="success">
              <span class="font-medium-4 font-weight-light">€</span>
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="h5 font-weight-bolder mb-0">
              {{ $t('Coasts') }}
            </h4>
            <b-card-text class="font-small-3 mb-0">
              <span v-if="event.free_event">{{ $t('Free of charge') }}</span>
              <span v-else>{{ event.credits }} {{ $t('Credits') }}</span>
            </b-card-text>
          </b-media-body>
        </b-media>
      </b-col>
      <b-col class="mb-1">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar size="40" variant="success">
              <feather-icon size="20" icon="ClockIcon"/>
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="h5 font-weight-bolder mb-0">
              {{ $t('Appointments') }}
            </h4>
            <b-card-text class="font-small-3 mb-0">
              {{ event.timeslots.length }} {{ $t('Appointments') }}
            </b-card-text>
          </b-media-body>
        </b-media>
      </b-col>
      <b-col class="mb-1">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar size="40" :variant="(event.is_expired ? 'danger' : 'success')">
              <feather-icon size="20" icon="CalendarIcon"/>
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="h5 font-weight-bolder mb-0">
              {{ $t('Registration deadline') }}
            </h4>
            <b-card-text class="font-small-3 mb-0">
              {{ formateDate(event.deadline) }}
            </b-card-text>
          </b-media-body>
        </b-media>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {BAvatar, BCard, BCardText, BCol, BMedia, BMediaAside, BMediaBody, BRow} from "bootstrap-vue";
import moment from "moment";

export default {
  name: "EventInfoBox",
  props: ['event', 'expert'],
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText
  },
  data() {
    return {

    }
  },
  methods: {
    formateDate(value) {
      return moment(String(value)).format("DD.MM.YYYY")
    }
  }
}
</script>

<style lang="scss">
</style>
