<template>
  <b-card>
    <h2 class="h4 mb-2">{{ $t('Booking and payment') }}</h2>
    <ul class="list-unstyled">
      <li class="d-flex align-items-center">
        <b-avatar variant="light-primary" square class="mr-1">
          <feather-icon icon="CreditCardIcon" />
        </b-avatar>
        <span class="align-self-center">
            <span v-if="event.free_event">
              {{ $t('The event is for free of charge.') }}
            </span>
            <span v-else>{{ event.credits }} {{ $t('Credits') }}</span>
        </span>
      </li>
      <li class="mt-1 d-flex">
        <b-avatar variant="light-primary" square class="mr-1">
          <feather-icon icon="UserPlusIcon" />
        </b-avatar>
        <span class="d-inline-block align-self-center">
          <span v-if="event.payment_type == 2">
            {{ $t('You are booking one place for this event.') }}
          </span>
          <span v-else>
            {{ $t('You book the entire event. After booking, you have the option to invite other employees from your company to this event.') }}
          </span>
        </span>
      </li>
      <li class="d-flex mt-1">
        <b-avatar variant="light-primary" square class="mr-1">
          <feather-icon icon="XCircleIcon" />
        </b-avatar>
        <span class="align-self-center">
            <span class="d-block font-weight-bold">{{ $t('Cancelation conditions') }}</span>
            <span v-if="!('cancellation_conditions' in event) || event.cancellation_conditions.length == 0 || event.free_event">
              {{ $t('You can cancel the booking at any time free of charge.') }}
            </span>
          <span v-else class="mt-1 d-block">
            <span class="d-block" v-for="condition in event.cancellation_conditions" :key="condition.index">
              <strong>{{ $t('until {hours} hours before starting the event:', {hours: condition.hours}) }} </strong> {{ condition.credits }} Credits
            </span>
          </span>
        </span>
      </li>
    </ul>
  </b-card>
</template>
<script>
import {BAvatar, BCard, BCardText, BCol, BMedia, BMediaAside, BMediaBody, BRow} from "bootstrap-vue";

export default {
  name: "EventInfoBox",
  props: ['event', 'expert'],
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText
  },
  data() {
    return {
      languages: [
        {
          value: 'de',
          text: this.$t('German')
        },
        {
          value: 'en',
          text: this.$t('English')
        },
        {
          value: 'es',
          text: this.$t('Spanish')
        },
      ],
    }
  },
  methods: {
    getLanguageNameByISOCode(lang) {
      let language = this.languages.filter(obj => {
        return obj.value === lang
      })
      if(!language.length) return lang

      return language[0].text
    }
  }
}
</script>

<style lang="scss">
</style>
