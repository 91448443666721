<template>
  <div>
    <div class="bg-light-primary rounded-top text-center">
      <b-img
          :src="require('@/assets/images/illustration/email.svg')"
          alt="Meeting Pic"
          height="170"/>
    </div>
    <b-card :title="$t('General Event information')" class="p-md-4 p-0 ">
      <b-row class="justify-content-between pt-2">
        <b-col cols="12" sm="12" md="12" lg="3" xl="3">
          <div class="card_info_item">
            <div class="card_content">
              <p class="info_item_title">{{ $t('Event title') }}</p>
              <p class="info_item_txt"> {{ event.name }}</p>
            </div>
          </div>
          <div class="card_info_item">
            <div class="card_content">
              <p class="info_item_title">{{ $t('Event Language') }}</p>
              <p class="info_item_txt"> {{ langFullName(event.language) }}</p>
            </div>
          </div>
          <div class="card_info_item">
            <div class="card_content">
              <p class="info_item_title">{{ $t('Event Description') }}</p>
              <p class="info_item_txt" v-html="event.description"></p>
            </div>
          </div>
          <div class="card_info_item">
            <div class="card_content">
              <p class="info_item_title">{{ $t('Target group') }}</p>
              <p class="info_item_txt"> {{ event.target }} </p>
            </div>
          </div>
          <div class="card_info_item">
            <div class="card_content">
              <p class="info_item_title">{{ $t('Agenda') }}</p>
              <p class="info_item_txt">{{ event.agenda }}</p>
            </div>
          </div>
        </b-col>
        <b-col cols="12" sm="12" md="12" lg="3" xl="3">
          <div class="card_info_item card-with-image d-flex">
            <div class="image_side">
              <b-avatar
                  size="45px"
                  :src="expert.expertdetails.avatar.url"
              />
            </div>
            <div class="card_content">
              <p class="info_item_title">{{ $t('Host') }}</p>
              <p class="info_item_txt"> {{ event.host }}</p>
            </div>
          </div>
          <div class="card_info_item  empty_card_image">
            <div class="card_content">
              <p class="info_item_title">{{ $t('Organizer') }}</p>
              <p class="info_item_txt">{{ event.organizer }}</p>
            </div>
          </div>
          <div class="card_info_item  empty_card_image">
            <div class="card_content">
              <p class="info_item_title">{{ $t('Contact person') }}</p>
              <p class="info_item_txt" v-html="event.contactperson"></p>
            </div>
          </div>
        </b-col>
        <b-col cols="12" sm="12" md="12" lg="3" xl="3">
          <div class="card_info_item card-with-image d-flex">
            <div class="image_side with_icon">
              <div class="icon_box">
                <feather-icon style="stroke: #016565" size="20" icon="MapPinIcon"/>
              </div>
            </div>
            <div class="card_content">
              <p class="info_item_title" v-if="event.eventlocation == 1">{{ $t('Event location') }}</p>
              <p class="info_item_txt" v-if="event.eventlocation == 1"> {{ event.street }} , {{ event.city }}</p>
              <p class="info_item_title" v-if="event.eventlocation == 2">{{ $t('Online Event') }}</p>
              <p class="info_item_txt" v-if="event.eventlocation == 2"> {{
                  $t('You will get access to Event Room once the Booking Process is successfully completed')
                }}</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCard,
  BImg,
  BCol,
  BAvatar,
} from 'bootstrap-vue'

export default {
  name: "EventGeneral",
  props: ['event', 'expert'],
  components: {
    BRow,
    BCard,
    BImg,
    BCol,
    BAvatar,
  },
  data() {
    return {
      languages: [
        {
          value: 'de',
          text: this.$t('German')
        },
        {
          value: 'en',
          text: this.$t('English')
        },
        {
          value: 'es',
          text: this.$t('Spanish')
        },
      ],
    }
  },

  methods: {
    langFullName(lang) {
      let language = this.languages.filter(obj => {
        return obj.value === lang
      })
     if(!language.length) return lang
      return language[0].text
    }
  }
}
</script>

<style scoped lang="scss">


</style>
