<template>
  <div>
    <b-button v-if="!event.is_expired"
        :to="{ name: 'event-booking',
                 params: {eventid: event.id}
              }"
        class="book_event"
        type="submit"
    > {{ $t('Book the event now!') }}
    </b-button>
    <b-button v-else
              disabled
              class=""
              type="submit"
    > {{ $t('Registration period has expired!') }}
    </b-button>
  </div>
</template>
<script>
import {BButton} from "bootstrap-vue";

export default {
  name: "EventBookingButton",
  props: ['event'],
  components: {
    BButton
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
</style>
